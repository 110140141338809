import React, { useState, useEffect } from "react";
import './Head.css';
import { Link } from "react-router-dom";

const Head = () => {
  const [location, setLocation] = useState("France"); // Default location
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch weather data from wttr.in API with extended details
    const fetchWeather = async () => {
      try {
        // Requesting detailed weather data including clouds, wind, sun/moon
        const response = await fetch(
          `https://wttr.in/${location}?format=%C+%t+%w+%h+%l+%s+%S+%m+%M+%p`
        );
        const data = await response.text();
        const weatherDetails = parseWeatherData(data); // Parse and organize the response data
        setWeatherData(weatherDetails);
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération de la météo :", error);
        setLoading(false);
      }
    };

    fetchWeather();
  }, [location]);

  // Function to parse weather data
  const parseWeatherData = (data) => {
    const weatherParts = data.split(" "); // Split the response into separate data points
    const temperature = weatherParts[1]; // Temperature
    const wind = weatherParts[2]; // Wind speed
    const windDirection = weatherParts[3]; // Wind direction
    const condition = weatherParts[0]; // Weather condition (Clear, Cloudy, etc.)
    
    return {
      condition,
      temperature,
      wind,
      windDirection,
    };
  };

  // Function to map weather condition to icons
  const getWeatherIcon = (condition) => {
    switch (condition) {
      case "Clear":
        return "☀️"; // Sun
      case "Cloudy":
        return "☁️"; // Cloud
      case "Partly cloudy":
        return "🌥️"; // Partly cloudy
      case "Rain":
        return "🌧️"; // Rain
      case "Thunderstorm":
        return "⛈️"; // Thunderstorm
      case "Snow":
        return "❄️"; // Snow
      default:
        return "🌤️"; // Default: Sun
    }
  };

  return (
    <section className="head">
      <div className="container flexSB paddingTB">
        <div className="logo">
        <Link to="/"><img src="../images/logo.png" alt="Logo" /></Link>
        </div>
        
        <div className="ad">
          {/* Display weather if fetched successfully */}
          {loading ? (
            <p>Chargement...</p>
          ) : (
            <div className="weather-widget">
              <h3 className="weather-title">Météo à {location}</h3> {/* Title showing the location */}
              <div className="weather-icons">
                <span className="icon">{getWeatherIcon(weatherData.condition)}</span>
                <span className="temperature">{weatherData.temperature}</span>
                <span className="wind">{weatherData.wind}</span>
                <span className="wind-direction">({weatherData.windDirection})</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Head;
