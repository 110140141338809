import React, {useState, useEffect} from "react"
import Discover from "./discover/Discover"
import Hero from "./hero/Hero"
import axios from "axios"
import Homes from "./mainContent/homes/Home"

const Homepages = () => {
  const [newsDetail, setNewsDetail] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    axios
      .get(`https://aceblog.fr/admin/api/meta`)
      .then((response) => {
        if (response.data) {
          setNewsDetail(response.data);
        } else {
          setNewsDetail(null);
        }
      })
      .catch((err) => {
        console.error("Error fetching news details:", err);
        setError("Failed to fetch news details.");
      });
  }, []); // Empty dependency array ensures the effect runs only once
      useEffect(() => {
        if (newsDetail) {
          // Set the document title
        document.title = newsDetail.meta_title || newsDetail.title;
        // Check if the meta title already exists
        let metaTitleTag = document.querySelector('meta[name="title"]');
        if (!metaTitleTag) {
          // Create and append the meta title if it does not exist
          metaTitleTag = document.createElement("meta");
          metaTitleTag.name = "title";
          document.head.appendChild(metaTitleTag);
        }
        metaTitleTag.content = newsDetail.meta_title || "LetempsDZ";
        // Check if the meta description already exists
        let metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (!metaDescriptionTag) {
          // Create and append the meta description if it does not exist
          metaDescriptionTag = document.createElement("meta");
          metaDescriptionTag.name = "description";
          document.head.appendChild(metaDescriptionTag);
        }
        metaDescriptionTag.content = newsDetail.meta_description || "No description available.";
      }
      }, [newsDetail]);
  return (
    <>
      <Hero />
      <Homes />
      <Discover />
    </>
  )
}

export default Homepages
