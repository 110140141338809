import React from "react"
import Header from "./components/common/header/Header"
import "./App.css"
import Homepages from "./components/home/Homepages"
import Footer from "./components/common/footer/Footer"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import SinglePage from "./components/singlePage/SinglePage"
import NewsDetail from "./components/singlePage/NewsDetails"
import AboutUs from "./pages/about-us"
import ContactUs from "./pages/contact-us"
import PrivacyPolicy from "./pages/privacy-policy"
import SearchResults from "./pages/searchResults"
import NotFound from "./pages/NotFound"
import AllNewsPage from "./components/singlePage/AllNewsPage"
import WriterPage from "./components/singlePage/WriterPage"
const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Homepages />} /> {/* Home page route */}
          <Route path="/:slug" element={<NewsDetail />} />
          <Route path="/categorie/:category" element={<SinglePage />} /> {/* Category page */}
          <Route path="/autor/:writer" element={<WriterPage />} />
          <Route path="/nouvelles" element={<AllNewsPage />} /> 
          <Route path="/à-propos -de-nous" element={<AboutUs />} /> {/* About Us page */}
          <Route path="/contactez-nous" element={<ContactUs />} /> {/* Contact Us page */}
          <Route path="/politique-de-confidentialite" element={<PrivacyPolicy />} />
          <Route path="/recherche" element={<SearchResults />} />
          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  )
}

export default App
